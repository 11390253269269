import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CardLayoutItem } from '@lib/ia/src/layouts/CardLayout/types';
import { useIaClub } from '@lib/web/hooks';

import { getIaClubCta, useIaClubCtaAvailableActions } from './iaClubCta';

export default function useIaClubCtaAction(
  clubSlug: string,
  options?: {
    onJoined?: (target: CardLayoutItem) => void;
    onCanceled?: (target: CardLayoutItem) => void;
    onAccepted?: (target: CardLayoutItem) => void;
    onLeaved?: (target: CardLayoutItem) => void;
    onJoinError?: () => void;
    onRequestError?: () => void;
    onAcceptError?: () => void;
    onCancelError?: () => void;
  }
) {
  const { club } = useIaClub(clubSlug);
  const { t } = useTranslation();
  const availableActions = useIaClubCtaAvailableActions({
    slug: clubSlug,
    ...options,
  });

  return useMemo(() => {
    const iaClubCta = club
      ? getIaClubCta(t, club.clubSlug, club.joinStatus, club.privacy)
      : null;

    if (!iaClubCta) return { club, cta: null, action: null };

    return {
      club,
      clubCta: iaClubCta,
      clubCtaAction:
        iaClubCta.type === 'link' ? null : availableActions[iaClubCta.value],
    };
  }, [t, availableActions, club]);
}
