/**
 * the returning user lands on the top level of the core page of previous sessions
 *
 * core pages:
 * 1. Discover
 * 2. Marketplace
 * 3. Practice for diff clubs
 * 4. Analytics for diff clubs
 */

import { useEffect, useRef } from 'react';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useAuth } from '@lib/web/apis';
import { call } from '@lib/web/utils';

const CORE_PATHNAMES_REGEX = /\/marketplace|\/club|\/link/i;

export default function useRecordCorePage() {
  const { asPath } = useRouter();
  const { isLogged } = useAuth();
  const lastTrackedPage = useRef<string | null>(null);

  useEffect(() => {
    if (!isLogged) return;

    // Strip out query parameters
    const cleanPath = asPath.split('?')[0];

    if (
      (CORE_PATHNAMES_REGEX.test(cleanPath) || cleanPath === '/') &&
      cleanPath !== lastTrackedPage.current
    ) {
      call(axios.post('/api/auth/track-page', { page: cleanPath }));
      lastTrackedPage.current = cleanPath; // Update the last tracked page
    }
  }, [isLogged, asPath]);
}
