import { useCallback, useContext } from 'react';

import { TriggerTemporaryMenuType } from '../context';
import {
  ClubMenuVariant,
  MainLayoutContext,
  MenuVariant,
  ProfileMenuVariant,
  TemporaryMenuVariant,
} from '../context';

export default function useMainLayout() {
  const [value, setValue] = useContext(MainLayoutContext);

  const clearMenu = () => {
    setValue((st) => ({
      ...st,
      menuTarget: null,
      currentMenu: null,
      currentClub: null,
      currentClubGroup: null,
      clubMenuTarget: null,
      profileMenuTarget: null,
      temporaryMenuTarget: null,
      triggerTemporaryMenuType: undefined,
    }));
  };

  const clearTemporaryMenu = useCallback(() => {
    setValue((st) => ({
      ...st,
      temporaryMenuTarget: null,
      triggerTemporaryMenuType: undefined,
    }));
  }, [setValue]);

  const seeMenu = (target: MenuVariant) => {
    setValue((st) => ({
      ...st,
      menuTarget: target,
    }));
  };

  const seeClubMenu = (target: ClubMenuVariant | null) => {
    setValue((st) => ({
      ...st,
      clubMenuTarget: target,
    }));
  };

  const seeProfileMenu = (target: ProfileMenuVariant | null) => {
    setValue((st) => ({
      ...st,
      profileMenuTarget: target,
    }));
  };

  const seeClub = (clubSlug?: string) => {
    setValue((st) => ({
      ...st,
      menuTarget: 'club',
      currentClub: clubSlug,
    }));
  };
  const seeClubs = (
    triggerTemporaryMenuType: TriggerTemporaryMenuType = 'clicked'
  ) => {
    setValue((st) => ({
      ...st,
      temporaryMenuTarget: 'clubs',
      triggerTemporaryMenuType,
    }));
  };
  const seeNotifications = () => {
    setValue((st) => ({
      ...st,
      temporaryMenuTarget: 'notifications',
      triggerTemporaryMenuType: 'clicked',
    }));
  };
  const seeAddFriends = () => {
    setValue((st) => ({
      ...st,
      temporaryMenuTarget: 'addFriends',
      triggerTemporaryMenuType: 'clicked',
    }));
  };
  const openTemporaryMenu = useCallback(
    (
      menu: TemporaryMenuVariant,
      triggerTemporaryMenuType: TriggerTemporaryMenuType = 'clicked'
    ) => {
      setValue((st) => ({
        ...st,
        temporaryMenuTarget: menu,
        triggerTemporaryMenuType,
      }));
    },
    [setValue]
  );

  return {
    ...value,
    seeMenu,
    seeClub,
    seeClubs,
    clearMenu,
    clearTemporaryMenu,
    seeClubMenu,
    seeProfileMenu,
    seeNotifications,
    seeAddFriends,
    openTemporaryMenu,
  };
}
