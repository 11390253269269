import { ReactNode } from 'react';

import IaDataContext, { IaDataContextValueType } from './IaDataContext';

type IaDataContextProviderProps = IaDataContextValueType & {
  children: ReactNode;
};

export default function IaDataContextProvider({
  token,
  sid,
  apiUrl,
  children,
}: IaDataContextProviderProps) {
  return (
    <IaDataContext.Provider value={{ token, sid, apiUrl }}>
      {children}
    </IaDataContext.Provider>
  );
}
