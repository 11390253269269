import { useCallback } from 'react';
import { useNotifications } from '@lib/web/hooks';

import useMainLayout from '../../layouts/MainLayout/hooks/useMainLayout';

import useAllJoinedClubs from './useAllJoinedClubs';

export default function useReloadBaseData() {
  const { mutate: clubNotificationsMutate } = useNotifications();
  const { mutate: allJoinedClubsMutate } = useAllJoinedClubs(); // switch club LHS
  const { temporaryMenuTarget } = useMainLayout();

  return useCallback(async () => {
    if (temporaryMenuTarget === 'clubs') {
      await allJoinedClubsMutate();
    }

    clubNotificationsMutate();
  }, [allJoinedClubsMutate, clubNotificationsMutate, temporaryMenuTarget]);
}
