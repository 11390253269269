import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import Router from 'next/router';
import { ModalProps } from '@mui/material/Modal';
import { EmphasizeButton, PopupActions, PopupOverlay } from '@front/ui';
import { useIaClubStatus } from '@lib/web/hooks';
import { clearRouteParams } from '@lib/web/utils';

import useIaClubCtaAction from '../../ia/club/useIaClubCTAAction';

const styles = {
  modal: {
    position: 'absolute',
    '& .MuiBackdrop-root': {
      position: 'absolute',
    },
  },
};

type ClubErrorPopupProps = {
  sx?: ModalProps['sx'];
  slug: string;
  sid?: string;
};
export default function ClubErrorPopup({ sx, slug, sid }: ClubErrorPopupProps) {
  const { club, joined, notFound } = useIaClubStatus(slug);
  const [loading, setLoading] = useState(false);
  const sxProps = Array.isArray(sx) ? sx : [sx];

  const { clubCta, clubCtaAction } = useIaClubCtaAction(slug);
  const handleJoinClick = async () => {
    setLoading(true);
    if (!clubCta || !club) {
      setLoading(false);
      return;
    }

    if (clubCta.type === 'link' || !clubCtaAction) {
      Router.push(clubCta.value);
    } else {
      await clubCtaAction.action({
        id: club.clubSlug,
        title: club.clubName,
      });
    }
    setLoading(false);
  };

  const hidePopup =
    !club || joined || joined === undefined || !clubCta || !!sid;

  useEffect(() => {
    if (!hidePopup) {
      Router.replace(
        {
          pathname: Router.pathname,
          query: { ...Router.query, blocker: 'club-error' },
        },
        undefined,
        { shallow: true }
      );
    }
  }, [hidePopup, slug]);

  useEffect(() => {
    return () => {
      clearRouteParams(['blocker']);
    };
  }, []);

  if (hidePopup) return null;

  // 404
  if (notFound)
    return (
      <PopupOverlay
        title="Club not found"
        body="Oops! We couldn’t find the club that you are looking for 😢"
        open
        modalProps={{
          disablePortal: true,
          sx: [styles.modal, ...sxProps],
        }}
        actionComponent={
          <PopupActions>
            <EmphasizeButton component={Link} href="/">
              Discover Clubs
            </EmphasizeButton>
          </PopupActions>
        }
      />
    );

  return (
    <PopupOverlay
      title={club.clubName}
      body="You have not joined this club, please join and continue."
      open
      modalProps={{
        disablePortal: true,
        sx: [styles.modal, ...sxProps],
      }}
      actionComponent={
        <PopupActions>
          <EmphasizeButton variant="outlined" component={Link} href="/">
            Discover Clubs
          </EmphasizeButton>
          <EmphasizeButton onClick={handleJoinClick} loading={loading}>
            {clubCta.text}
          </EmphasizeButton>
        </PopupActions>
      }
    />
  );
}
