import React, { ReactNode, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Box } from '@mui/material';
import { useUser } from '@auth0/nextjs-auth0/client';
import { GuestContext } from '@front/helper';
import IaDataContextProvider from '@lib/ia/src/core/IaData/IaDataProvider';
import { PinChatViewSlug, useIaPinChats } from '@lib/web/apis';
import { LoginPopup } from '@lib/web/ui';

import { updateViewedSlug } from '../../actions/layoutActions';
import ClubErrorPopup from '../../components/ClubErrorPopup';
import { useAppDispatch } from '../../hooks/redux';
import useRecordCorePage from '../../hooks/utils/useRecordCorePage';
import useUpdateOnlineStatus from '../../hooks/utils/useUpdateOnlineStatus';

const IGNORE_PATHNAMES = ['/club/[slug]'];

export default function RootLayout({ children }: { children: ReactNode }) {
  useRecordCorePage();
  useUpdateOnlineStatus();
  useIaPinChats({ viewSlug: PinChatViewSlug.PinChatOverlayDefault, limit: 0 }); // load initial for better UX;
  const dispatch = useAppDispatch();
  const router = useRouter();
  const clubSlug = (router.query.clubSlug || router.query.slug || '') as string;
  const sid = (router.query.sid || '') as string;
  const { user } = useUser();
  const { guest } = useContext(GuestContext);

  const token = (user?.accessToken || guest.accessToken || '') as string;

  useEffect(() => {
    if (clubSlug) {
      dispatch(updateViewedSlug(clubSlug));
    }
  }, [clubSlug, dispatch]);
  return (
    <IaDataContextProvider token={token} sid={sid} apiUrl={process.env.API_URL}>
      <Box display="flex" flexDirection="column" height="100%">
        {!sid && <LoginPopup />}
        <Box flex={1}>
          {children}
          {!!clubSlug && !IGNORE_PATHNAMES.includes(router.pathname) && (
            <ClubErrorPopup slug={clubSlug} sid={sid} />
          )}
        </Box>
      </Box>
    </IaDataContextProvider>
  );
}
