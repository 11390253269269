import { useCallback, useEffect } from 'react';
import { useVisibilityChange } from '@front/helper';
import { apis, useAuth, UserOnlineStatusUpdate } from '@lib/web/apis';
import { call } from '@lib/web/utils';

import { QuizState } from '../../reducers/quizReducer/types';
import { useAppSelector } from '../redux';

const UPDATE_INTERVAL_MS = 60 * 1000; // Update interval in milliseconds (60 seconds)

export default function useUpdateOnlineStatus() {
  const { isLogged } = useAuth();

  const isPracticing = useAppSelector(
    (st) =>
      st.quiz.state === QuizState.Playing || st.quiz.state === QuizState.Paused
  );

  const updateUserStatus = useCallback((): void => {
    void call(
      apis.user.updateUserStatus(
        isPracticing
          ? UserOnlineStatusUpdate.PRACTICING
          : UserOnlineStatusUpdate.ONLINE
      )
    );
  }, [isPracticing]);

  // when user re focus on browser
  useVisibilityChange({
    onVisibleChange: (isVisible: boolean): void => {
      if (isLogged && isVisible) {
        updateUserStatus();
      }
    },
  });

  useEffect(() => {
    if (isLogged) {
      updateUserStatus();
    }

    // Set up an interval to update the user status every minute
    const intervalId = setInterval((): void => {
      if (isLogged) {
        updateUserStatus();
      }
    }, UPDATE_INTERVAL_MS);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [isLogged, updateUserStatus]);
}
